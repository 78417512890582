<template>
	<JetAuthenticationCard>

		<template #logo>
			<JetAuthenticationCardLogo/>
		</template>

		<div v-if="status" class="">
			{{ status }}
		</div>

		<div class="form-signin">


			<form @submit.prevent="submit">
				<!-- <JetLabel for="email" value="Username" class="visually-hidden"/>
				<JetInput id="email" type="text" class="form-control"
				placeholder="Имя пользователя" v-model="form.username"
				required autofocus/> -->
				<div v-if="!recoveringPassword">
					<v-text-field :error="hasErrors || loginHasSpaces" class="mb-3" hide-details density="comfortable" v-model="form.username" type="email" placeholder="Логин">
						<template v-slot:append-inner>
							<AuthSvg :name="'email'" :isError="hasErrors || loginHasSpaces"></AuthSvg>
						</template>
					</v-text-field>
					<v-text-field :error="hasErrors" hide-details density="comfortable" v-model="form.password" :type="passwordVisible ? '' : 'password'" placeholder="Пароль">
						<template v-slot:append-inner>
							<AuthSvg @click="passwordVisible = !passwordVisible" style="cursor: pointer;" :name="'password'" :isError="hasErrors"></AuthSvg>
						</template>
					</v-text-field>
					<!-- <JetLabel for="password" value="Password" class="visually-hidden"/>
						<JetInput id="password" type="password" class="form-control"
						placeholder="Пароль"
						v-model="form.password" required
						autocomplete="current-password"/> -->
					<div class="form-signin__info mb-3">
						<label class="form-signin__remember">
							<JetCheckbox name="remember" v-model="form.remember"/>
							<span class="ml-2 text-sm text-gray-600">Запомнить меня</span>
						</label>
						<a
							ref="popover"
							class="form-signin__forget"
							title="Забыли пароль?"
							@click="recoveringPassword = true"
							data-bs-content="Очень-очень жаль :-("
							data-bs-trigger="focus"
							href="#" tabindex="0">Забыли пароль?
						</a>
					</div>
					<v-btn @click="submit()" :disabled="!form.username || !form.password || form.processing" class="form-signin__button"> Войти </v-btn>
				</div>
				<div v-else>
					<h3 class="form-signin__forget--title"> Забыли пароль?</h3>
					<v-text-field class="mb-3" :error="hasErrors" hide-details density="comfortable" v-model="email" :type="email" placeholder="Email">
						<template v-slot:append-inner>
							<AuthSvg style="cursor: pointer;" :name="'mail'" :isError="hasErrors"></AuthSvg>
						</template>
					</v-text-field>
					<v-btn @click="" class="form-signin__button mb-3" :disabled="email.length < 4"> Восстановить</v-btn>
					<a
							ref="popover"
							class="form-signin__forget form-signin__forget--back"
							title="Забыли пароль?"
							@click="recoveringPassword = false"
							data-bs-content="Очень-очень жаль :-("
							data-bs-trigger="focus"
							href="#" tabindex="0">Назад
						</a>
				</div>
				<!-- <JetButton class="w-100 btn-lg btn-primary" :disabled="form.processing">
					Войти
				</JetButton> -->

				<!--
					<InertiaLink v-if="canResetPassword"
					:href="route('password.request')"
					data-bs-toggle="tooltip"
					>Забыли пароль?</InertiaLink>
					-->
					<!--
						&middot;
						<InertiaLink :href="route('register')">Регистрация</InertiaLink>
						-->
				</form>
			<div v-if="loginHasSpaces" class="mt-3">
				<SvgAsset class="mr-2" :name="'error'"></SvgAsset><span class="ml-2 text-error">Введите логин без пробелов</span>
			</div>
			<JetValidationErrors :errors="errors"/>
		</div>
	</JetAuthenticationCard>
</template>

<script>
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import AuthSvg from './AuthSvg.vue';
import Popover from 'bootstrap/js/src/popover';
import SvgAsset from '@/Jetstream/SvgAsset.vue';
import { usePage } from '@inertiajs/inertia-vue3';

// const errors = computed(() => usePage().props.value.errors);
// const hasErrors = computed(() => Object.keys(errors.value).length > 0);

export default {
	components: {
		JetAuthenticationCard,
		JetAuthenticationCardLogo,
		JetButton,
		JetInput,
		JetCheckbox,
		JetLabel,
		AuthSvg,
		SvgAsset,
		JetValidationErrors,
	},

	props: {
		canResetPassword: Boolean,
		status: String,
	},

	data() {
		return {
			form: this.$inertia.form({
				username: '',
				password: '',
				remember: false,
			}),
			passwordVisible: false,
			recoveringPassword: false,
			email: '',
			localErrors: [],
		};
	},
	computed: {
		errors() {
			return usePage().props.value.errors;
		},
		hasErrors() {
			return Object.keys(this.errors).length > 0
		},
		loginHasSpaces() {
			let thereIs = /\s/g.test(this.form.username);
			return thereIs;
		},
	},

	mounted() {
		console.log(usePage(), 'usePage');
		new Popover(this.$refs.popover);
	},

	methods: {
		submit() {
			this.form.transform(data => ({
				...data,
				remember: this.form.remember ? 'on' : '',
			})).post(this.route('login'), {
				onFinish: () => this.form.reset('password'),
			});
		},
	},
};
</script>
<style lang="scss">
.v-field--variant-outlined {
	border: 1px solid rgba(25, 25, 25, 1);
	border-radius: 0;
}
*:focus {
	outline: none;
}
.v-field {
	&:focus {
		border: none;
	}
	&--active {
		background-color: rgba(230, 230, 230, 1);
	}
	&--focused {
		background-color: white;
		border-width: 0 !important;
		box-shadow: 0px 0px 8px 0px rgba(113, 113, 113, 1);
	}
	&--error {
		background-color: white;
		// background-color: rgba(230, 230, 230, 1) !important;
		border: 1px solid rgba(215, 74, 65, 1);
		box-shadow: 0px 0px 8px 0px rgba(215, 74, 65, 1);
		color: rgba(215, 74, 65, 1);
	}
}

.v-btn--disabled {
	background-color: rgba(168, 168, 168, 1) !important;
	color: white !important;
}
</style>